// Live

import * as ImageBoardGame from "assets/Desktop/images/game/board-game";
import * as ImageESport from "assets/Desktop/images/game/e-sports";
import * as ImageFishing from "assets/Desktop/images/game/fishing";
import ListSymbolLive from "assets/Desktop/images/game/list_symbol-live.png";
import ListSymbolSport from "assets/Desktop/images/game/list_symbol-sport.png";
import * as ImageLiveCasino from "assets/Desktop/images/game/live-casino";
import * as ImageSlots from "assets/Desktop/images/game/slots";
import * as ImageLiveSport from "assets/Desktop/images/game/sports";
import { GameTypeEnum } from "enums/game";

import { PATH } from "./path";

export interface SymbolsType {
  icon: string;
  iconSelected: string;
  providerCode: string;
}

export interface DataDetailCategoryType {
  providerCode: string;
  titleImage: string;
  description: string;
  image: string;
  providerName: string;
  providerImage?: string;
  listSymbolImg?: string;
}

export interface ListDataType {
  id: string;
  name: string;
  symbols: SymbolsType[];
  data: DataDetailCategoryType[];
  path: string;
}

export const LIST_DATA: ListDataType[] = [
  {
    id: GameTypeEnum.LiveCasino,
    name: "Live",
    path: PATH.liveGamePage,
    symbols: [
      {
        icon: ImageLiveCasino.IconBBIN,
        iconSelected: ImageLiveCasino.IconBBINSelected,
        providerCode: "BBIN",
      },
      {
        icon: ImageLiveCasino.IconDG,
        iconSelected: ImageLiveCasino.IconDGSelected,
        providerCode: "DG",
      },

      {
        icon: ImageLiveCasino.IconEVO,
        iconSelected: ImageLiveCasino.IconEVOSelected,
        providerCode: "EVO",
      },
      {
        icon: ImageLiveCasino.IconPP,
        iconSelected: ImageLiveCasino.IconPPSelected,
        providerCode: "PP",
      },

      {
        icon: ImageLiveCasino.IconSX,
        iconSelected: ImageLiveCasino.IconSXSelected,
        providerCode: "SX",
      },

      {
        icon: ImageLiveCasino.IconWE,
        iconSelected: ImageLiveCasino.IconWESelected,
        providerCode: "WE",
      },
      {
        icon: ImageLiveCasino.IconWM,
        iconSelected: ImageLiveCasino.IconWMSelected,
        providerCode: "WM",
      },
    ],
    data: [
      {
        listSymbolImg: ListSymbolLive,
        providerCode: "BBIN",
        titleImage: ImageLiveCasino.titleBBIN,
        description: "categoryGame:contentBBin",
        image: ImageLiveCasino.imageBBIN,
        providerName: "categoryGame:Evolution",
        providerImage: ImageLiveCasino.BBin,
      },

      {
        listSymbolImg: ListSymbolLive,
        providerCode: "DG",
        titleImage: ImageLiveCasino.titleDG,
        description: "categoryGame:contentDream",
        image: ImageLiveCasino.imageDG,
        providerName: "categoryGame:Dream",
        providerImage: ImageLiveCasino.DreamGaming,
      },
      {
        listSymbolImg: ListSymbolLive,
        providerCode: "EVO",
        titleImage: ImageLiveCasino.titleEVO,
        description: "categoryGame:contentEvolution",
        image: ImageLiveCasino.imageEVO,
        providerName: "categoryGame:Evolution",
        providerImage: ImageLiveCasino.evolutionGaming,
      },
      {
        listSymbolImg: ListSymbolLive,
        providerCode: "PP",
        titleImage: ImageLiveCasino.titlePP,
        description: "categoryGame:contentPP",
        image: ImageLiveCasino.imagePP,
        providerName: "categoryGame:Dream",
        providerImage: ImageLiveCasino.PragmaticPlay,
      },
      {
        listSymbolImg: ListSymbolLive,
        providerCode: "SX",
        titleImage: ImageLiveCasino.titleSX,
        description: "categoryGame:contentSexy",
        image: ImageLiveCasino.imageSX,
        providerName: "categoryGame:Sexy",
        providerImage: ImageLiveCasino.SexyBaccarat,
      },
      {
        listSymbolImg: ListSymbolLive,
        providerCode: "WE",
        titleImage: ImageLiveCasino.titleWE,
        description: "categoryGame:contentWE",
        image: ImageLiveCasino.imageWE,
        providerName: "categoryGame:SA",
        providerImage: ImageLiveCasino.we,
      },
      {
        listSymbolImg: ListSymbolLive,
        providerCode: "WM",
        titleImage: ImageLiveCasino.titleWM,
        description: "categoryGame:contentWM",
        image: ImageLiveCasino.imageWM,
        providerName: "categoryGame:SA",
        providerImage: ImageLiveCasino.wm,
      },
    ],
  },
  {
    id: GameTypeEnum.Sport,
    path: PATH.sportGamePage,
    name: "Sports",
    symbols: [
      {
        icon: ImageLiveSport.Image_sports_icon_SBO,
        iconSelected: ImageLiveSport.Image_sports_icon_selected_SBO,
        providerCode: "SBO",
      },
      {
        icon: ImageLiveSport.Image_sports_icon_UG,
        iconSelected: ImageLiveSport.Image_sports_icon_selected_UG,
        providerCode: "UG",
      },
    ],
    data: [
      {
        listSymbolImg: ListSymbolSport,
        providerCode: "SBO",
        titleImage: ImageLiveSport.Image_sports_title_sbo,
        description: "categoryGame:contentSBO",
        image: ImageLiveSport.Image_sports_banner_SBO,
        providerImage: ImageLiveSport.Image_SBO_sport,
        providerName: "categoryGame:SABA",
      },
      {
        listSymbolImg: ListSymbolSport,
        providerCode: "UG",
        titleImage: ImageLiveSport.Image_sports_title_ug,
        description: "categoryGame:contentUG",
        image: ImageLiveSport.Image_sports_banner_UG,
        providerImage: ImageLiveSport.Image_UG_sport,
        providerName: "categoryGame:SABA",
      },
    ],
  },
  {
    id: GameTypeEnum.ESport,
    path: PATH.esportGamePage,
    name: "E-Sports",
    symbols: [
      {
        icon: ImageESport.Image_Esports_icon_IA,
        iconSelected: ImageESport.Image_Esports_icon_selected_IA,
        providerCode: "XADJ",
      },
      {
        icon: ImageESport.Image_Esports_icon_LH,
        iconSelected: ImageESport.sports_icon_selected_LH,
        providerCode: "LH",
      },
    ],
    data: [
      {
        listSymbolImg: ListSymbolSport,
        providerCode: "XADJ",
        titleImage: ImageESport.Image_esports_title_IA,
        description: "categoryGame:contentIA",
        image: ImageESport.Image_Esports_banner_IA,
        providerImage: ImageESport.Image_IA_sport,
        providerName: "categoryGame:IA",
      },
      {
        listSymbolImg: ListSymbolSport,
        providerCode: "LH",
        titleImage: ImageESport.Image_esports_title_LH,
        description: "categoryGame:contentTF",
        image: ImageESport.Image_Esports_banner_LH,
        providerImage: ImageESport.Image_LH_sport,
        providerName: "categoryGame:LH",
      },
    ],
  },
  {
    id: GameTypeEnum.Slot,
    name: "Slots",
    path: PATH.slotGamePage,
    symbols: [],
    data: [
      {
        providerCode: "IBEX",
        titleImage: "",
        providerImage: ImageSlots.Image_Ibex,
        description: "",
        image: "",
        providerName: "IBEX",
      },
      {
        providerCode: "RT",
        titleImage: "",
        providerImage: ImageSlots.Image_rt,
        description: "",
        image: "",
        providerName: "RED TIGER",
      },
      {
        providerCode: "SG",
        titleImage: "",
        providerImage: ImageSlots.Image_SPADE,
        description: "",
        image: "",
        providerName: "SPADE GAMING",
      },
      {
        providerCode: "SPRI",
        titleImage: "",
        providerImage: ImageSlots.Image_spribe,
        description: "",
        image: "",
        providerName: "SPRIBE",
      },
      {
        providerCode: "JK",
        titleImage: "",
        providerImage: ImageSlots.ImageSlotJoker,
        description: "",
        image: "",
        providerName: "Joker",
      },
      {
        providerCode: "BNG",
        titleImage: "",
        description: "",
        image: "",
        providerImage: ImageSlots.Image_bng,
        providerName: "Booongo",
      },
      {
        providerCode: "CQ9",
        titleImage: "",
        providerImage: ImageSlots.Image_cq9,
        description: "",
        image: "",
        providerName: "CQ9 GAMING",
      },
      {
        providerCode: "HB",
        titleImage: "",
        providerImage: ImageSlots.Image_haba,
        description: "",
        image: "",
        providerName: "Habanero",
      },
      {
        providerCode: "JDB",
        titleImage: "",
        providerImage: ImageSlots.Image_jdb,
        description: "",
        image: "",
        providerName: "	JDB Gaming",
      },
      {
        providerCode: "KA",
        titleImage: "",
        description: "",
        image: ImageSlots.SlotKA,
        providerImage: ImageSlots.SlotKA,
        providerName: "KA Gaming",
      },

      // {
      //   providerCode: "PGS",
      //   titleImage: "",
      //   providerImage: ImageSlots.SlotPGS,
      //   description: "",
      //   image: ImageSlots.SlotPGS,
      //   providerName: "PGS",
      // },
      {
        providerCode: "PP",
        titleImage: "",
        description: "",
        image: ImageSlots.SlotPragmaticPlay,
        providerImage: ImageSlots.SlotPragmaticPlay,
        providerName: "Pragmatic Play",
      },
      {
        providerCode: "RSG",
        titleImage: "",
        providerImage: ImageSlots.Image_rsg,
        description: "",
        image: ImageSlots.Image_rsg,
        providerName: "RSG",
      },
      {
        providerCode: "FC",
        titleImage: "",
        providerImage: ImageSlots.SlotFc,
        description: "",
        image: ImageSlots.SlotFc,
        providerName: "FaChai Gaming",
      },
    ],
  },
  {
    id: GameTypeEnum.Fishing,
    name: "Fishing",
    path: PATH.fishingGamePage,
    symbols: [],
    data: [
      {
        providerCode: "JK",
        titleImage: "",
        description: "",
        image: ImageFishing.Image_joker,
        providerImage: ImageFishing.Image_joker,
        providerName: "Joker Gaming",
      },
      {
        providerCode: "CQ9",
        titleImage: "",
        description: "",
        image: ImageFishing.Image_cq9,
        providerImage: ImageFishing.Image_cq9,
        providerName: "CQ9 Gaming",
      },
      {
        providerCode: "JDB",
        titleImage: "",
        description: "",
        image: ImageFishing.Image_jdb,
        providerImage: ImageFishing.Image_jdb,
        providerName: "JDB Gaming",
      },
    ],
  },
  {
    id: GameTypeEnum.BoardGame,
    name: "Board Games",
    path: PATH.boardGamePage,
    symbols: [],
    data: [
      {
        providerCode: "BL",
        titleImage: ImageBoardGame.ImageBoardGameBole,
        description: "",
        image: ImageBoardGame.ImageBoardGameBole,
        providerImage: ImageBoardGame.ImageBoardGameBole,
        providerName: "Bole",
      },
      {
        providerCode: "AVIA",
        titleImage: ImageBoardGame.ImageBoardGameAviatrix,
        description: "",
        image: ImageBoardGame.ImageBoardGameAviatrix,
        providerImage: ImageBoardGame.ImageBoardGameAviatrix,
        providerName: "AVIATRIX",
      },
    ],
  },
];
