import axiosRequest from "api";
import { payloadHelper } from "helpers/payload-helper";
import {
  PayloadRefferalType,
  ReferralPlayerResponseType,
  ReferralResponseType,
} from "types/referral";

export const referralApi = {
  async getReferral(username: string) {
    const response = await axiosRequest.post<ReferralResponseType>(
      "/",
      payloadHelper("Referral", username, { username })
    );
    return response.data;
  },
  async getReferralPlayerPromotion(payload: PayloadRefferalType) {
    const response = await axiosRequest.post<ReferralPlayerResponseType>(
      "/",
      payloadHelper("ReferralPlayerPromotion", payload.username, payload)
    );
    return response.data;
  },
};
